import {Link} from "react-router-dom";

const Footer = () => {
    return(
    <footer className="footer-1 footer-wrap">
        <div className="footer-widgets">            
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-md-6 col-xl-3 col-12 pr-xl-4">
                        <div className="single-footer-wid site_footer_widget newsletter_widget">
                            <div className="footer-logo">
                                <a href="/">
                                    <img src="assets/img/logowhite.png" alt="logo" style={{height: "80px",width: "100px"}}/>
                                </a>
                            </div>
                            <div className="footer-logo-2">
                                <a href="/">
                                    <img src="assets/img/logowhite.png" alt="logo" style={{height: "80px",width: "100px"}}/>
                                </a>
                            </div>
                            <p className="mt-4">Technology | Innovation | Entrepreneurship.</p>
                            <div className="newsletter_box">
                                <form>
                                    <input type="email" placeholder="Email address" required/>
                                  <button className="submit-btn" type="button">  <Link to="malito:hello@abiatechhub.com" target="_blank"> <i className="fal fa-envelope-open-text" ></i> </Link></button> 
                                </form>
                            </div>
                        </div>
                    </div> 
                    <div className="col-md-6 col-xl-2 col-12">                        
                        <div className="single-footer-wid">
                            <div className="wid-title">
                                <h4>Company</h4>
                            </div>
                            <ul>
                                <li><a href="/about">About Us</a></li>
                                <li><a href="https://cretehost.com/">Web Hosting</a></li>
                                <li><a href="/contact">Need a Career</a></li>
                                <li><a href="/refund_policy">Refund Policy</a></li>
                                <li><a href="https://blog.abiatechhub.com/">Blog Post</a></li>
                            </ul>
                        </div>
                    </div> 
                   
                    <div className="col-md-6 col-xl-3 col-12">                        
                        <div className="single-footer-wid contact_widget_2">
                            <div className="wid-title">
                                <h4>Contact Us</h4>
                            </div>
                            <div className="contact-us">
                                <div className="single-contact-info">
                                <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3971.2187863554536!2d7.491104674226888!3d5.534532733866766!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1042dcc55f4f0f9d%3A0xf30a6c9bcfab7dfa!2sAbia%20Tech%20Hub!5e0!3m2!1sen!2sng!4v1685530733902!5m2!1sen!2sng" target="_blank"><div className="icon">
                                    <i className="fas fa-map-marker-alt"></i>
                                    </div></Link>
                                    <div className="contact-info">
                                        <p>6 Warri street, Umuahia, Abia State</p>
                                    </div>
                                </div>
                                <div className="single-contact-info">
                                <Link to="malito:hello@abiatechhub.com" target="_blank"> <div className="icon">
                                        <i className="fas fa-envelope-open-text"></i>
                                    </div></Link>
                                    <div className="contact-info">
                                        <p>hello@abiatechhub.com</p>
                                    </div>
                                </div>
                                <div className="single-contact-info">
                                <Link to="tel:+234 805 274 9600" target="_blank"> <div className="icon">
                                        <i className="fas fa-phone"></i>
                                    </div> </Link>
                                    <div className="contact-info">
                                        <p>+234 805 274 9600</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="col-md-6 col-xl-3 col-12">
                        <div className="single-footer-wid recent_post_widget">
                            <div className="wid-title">
                                <h4>Follow us</h4>
                            </div>
              <div className="btn-wrapper profile"> 
                <a
                  target="_blank"
                  aria-label="twitter"
                  href="https://www.twitter.com/abiatechhub/"
                  className="btn btn-icon btn-light btn-round btn-simple m-2"
                  data-toggle="tooltip"
                  data-original-title="Follow us"
                  rel="noreferrer"
                  title="abia tech hub twitter"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  target="_blank"
                  aria-label="instagram"
                  href="https://www.instagram.com/abiatechhub/"
                  className="btn btn-icon btn-light  btn-round btn-simple m-2"
                  data-toggle="tooltip"
                  data-original-title="Follow us"
                  rel="noreferrer"
                  title="abia_tech_hub_instagram"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  target="_blank"
                  aria-label="facebook"
                  href="https://web.facebook.com/AbiaTechHub/"
                  className="btn btn-icon btn-light btn-round btn-simple m-2"
                  data-toggle="tooltip"
                  data-original-title="Like us"
                  rel="noreferrer"
                  title="abia_tech_hub_facebook"
                >
                  <i className="fab fa-facebook-square"></i>
                </a>
            </div> 
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </footer>
    )
}
export default Footer;

